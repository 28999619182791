import React from "react";
import Navbar from "../components/Navbar.jsx"
import Footer from "../components/footer.jsx"
import "../styles/home.css"

const Home = () => {
    return (
        <body className="homeContainer">
            <Navbar/>
            <section className="landingContainer">

            </section>
            <Footer/>
        </body>
    )
};

export default Home;