import Home from './views/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home/>} path='/'/>
      </Routes>
    </BrowserRouter>
  );
};
 
export default App;