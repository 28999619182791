import React from "react";
import "../styles/navbar.css"
import { faPhone, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = () => {
    return (
        <nav className="navbarContainer">
            <div className="navbarTop">
                <div className="headerContact">
                    <p className="phoneNumberHeader"> <FontAwesomeIcon icon={faPhone} style={{ fontSize: '15px' }} /> +56 9 4408 6807</p>
                    <p className="emailHeader"><FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '15px' }} /> comercial@edgcomercial.cl</p>
                </div>
            </div>
            <div className="navbarBottom">
                <img src="/images/EDG (1).png" alt="EDG Logo" className="logo" />
                <div className="section">
                    <ul>
                        <li className="sections">INICIO</li>
                        <li className="sections">EMPRESA</li>
                        <li className="sections">PRODUCTOS</li>
                        <li className="sections">CONTACTO</li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;