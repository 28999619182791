import React from "react";
import "../styles/footer.css"
import { faPhone, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
    return (
        <footer>
            <div className="logoFooterContainer">
                <img className="logoFooter" src="/images/EDG (2).png" alt="logoFooter"></img>
            </div>
            <div className="contactListContainer">
                <ul className="contactListUl">
                    <li><h2>Contacto</h2></li>
                    <li className="contactData"><FontAwesomeIcon icon={faPhone} style={{ fontSize: '15px' }} /> +56 9 4408 6807</li>
                    <li className="contactData"><FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '15px' }} /> comercial@edgcomercial.cl</li>
                    <li className="contactData"><FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '15px' }} /> ventas@edgcomercial.cl</li>
                    <li></li>
                </ul>
            </div>
            <div className="contactListForm">
                <h2>Vía Formulario</h2>
                <form>
                    <input placeholder="Nombre"></input>
                    <input placeholder="Correo"></input>
                    <input placeholder="Ciudad"></input>
                    <input placeholder="Telefono"></input>
                    <textarea  placeholder="Escriba su mensaje aquí..."></textarea>
                    <button>Enviar</button>
                </form>
            </div>
        </footer>
    );
};

export default Footer;